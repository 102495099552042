// white button
// default styles for our buttons
// here you can add styles that should apply to all buttons
// uses bootstrap custom button mixin and extends it
.bob-style {
    @include button-variant($hero-btn, rgb(145, 145, 145), $hero-btn, #333);
    margin: 3px; //this is needed tp teh background shadow does not get cut off on active
    border-radius: 40px;
    border: none;
    font-size: 17px;
    line-height: 1.5;
    max-width: 400px;
}

// dark button
// overrides default bob-style
.form-checksum,
.form-checksum-renewal,
.passage,
.text,
.accordion,
.button-block {
    .bob-style {
        @include button-variant($brand-heading-dark, rgb(145, 145, 145), $brand-heading-dark, $button-bg-light);
    }
}

#bob-btn-6.bob-style {
    @include button-variant($brand-heading-dark, $brand-heading-dark, $brand-heading-dark, $brand-heading-dark);
}

// gray button
// overrides default bob-style
.page_2 .bob-style,
.bob-frame .bob-style {
    @include button-variant(#EDF0F2, rgb(145, 145, 145), #e3e3e3, #333);
    padding: 8px 20px;
    border: solid 1px #333;
}


// gray button
// overrides default bob-style
.text-image .bob-style,
.bob-style.light-variant {
    @include button-variant(#fff, $brand-heading-dark, #fff, $brand-heading-dark);
    padding: 8px 20px;
    border: solid 1px $brand-heading-dark;
    text-decoration: none;
}

.text-image .bob-style {
    margin: 3px 20px 3px auto;
}

.bob-style.light-variant {
    display: block;
    margin: 10px auto 3px auto;
}


// variation of the default bob-style
.jumbotron .bob-style,
.hero .bob-style {
    padding: 20px 30px;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 30px;
    width: 85%;
}

// variation of the default bob-style
.portal .bob-style {
    padding: 6px 20px;
    width: 100%;
    border: solid 2px $brand-heading-dark;

    &:hover,
    &:active,
    &:focus {
        border: solid 2px $brand-heading-dark;
    }
}


// variation of the default bob-style
.contact .bob-style {
    padding: 8px 20px;
    margin: 20px auto 0 auto;
    width: 100%;
    max-width: 100%;
    line-height: 1.18;
}

// variation of the default bob-style
.passage,
.text,
.button-block {
    .bob-style {
        padding: 6px 40px;
    }
}

// variation of the default bob-style
.webinar-section {
    .bob-style {
        padding: 5px 35px;
        color: $brand-heading-dark;
    }
}

.subscription {
    .bob-style {
        color: $brand-heading-dark;
        background-color: #fff;
        max-width: 100%;
        width: 100%;
        padding: 12px;
    }
}

.devices .bob-style {
    padding: 6px 20px;
    width: 100%;
    border: solid 2px $brand-heading-dark;
    background-color: #fff;
    color: $brand-heading-dark;
    max-width: 400px;
}

.subscription-box .bob-style {
    @include button-variant($brand-heading-dark, rgb(145, 145, 145), $brand-heading-dark, $button-bg-light);

    color: #fff;
    font-weight: 500;
    width: 100%;
    max-width: 400px;
    padding: 15px 20px;
    
    &:hover,
    &:active,
    &:focus {
        color: #fff;
    }

    span {
        font-weight: 600;
        color: $brand-name-light;
    }
}

.logo-steps .bob-style {
    @include button-variant($brand-heading-dark, rgb(145, 145, 145), $brand-heading-dark, $button-bg-light);

    padding: 15px 40px;
}


.project-optimune {
    .hero .bob-style {
        width: 100%;
        max-width: 400px;
    }

    .portal {
        .bob-style {
            @include button-variant($brand-heading-dark, rgb(145, 145, 145), $brand-heading-dark, $button-bg-light);
        }

        .style-2 .bob-style {
            padding: 6px 20px;
            width: 100%;
            border: solid 2px $brand-heading-dark;
            border-radius: 40px;
            background-color: #fff;
            color: $brand-heading-dark;

            &:hover,
            &:active,
            &:focus {
                border: solid 2px $brand-heading-dark;
            }
        }
    }

    .form-checksum .bob-style {
        padding: 6px 20px;
        width: 100%;
        border: solid 2px $brand-heading-dark;
        background-color: #fff;
        color: $brand-heading-dark;

        &:hover,
        &:active,
        &:focus {
            border: solid 2px $brand-heading-dark;
            background-color: #fff;
            color: $brand-heading-dark;
        }
    }
}


@include media-breakpoint-up (lg) {

    // variation of the default bob-style
    .bob-style {
        font-size: 20px;
    }
}