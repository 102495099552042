.jumbotron {
    width: 100%;
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 30px;
    background-color: $brand-app;

    .container {
        padding: 0 10px;

        .bob-style {
            background-color: $hero-btn;
            color: #000;
            margin-bottom: 30px;
            width: 85%;
            border-radius: 40px;
            border: none;
            max-width: 300px;
            padding: 10px 20px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .content {
        color: #fff;
        margin-top: 20px;
        z-index: 10;

        h1 {
            font-size: 40px;
            line-height: 1.33;
            font-weight: 700;
            font-family: $font-family-base;
            margin-bottom: 30px;

            span {
                background-color: $brand-app;
                white-space: pre-wrap;
            }
        }

        h2 {
            font-size: 28px;
            line-height: 1.2;
            font-weight: 300;
            font-family: $font-family-base;
            margin-bottom: 30px;
        }

        p {
            font-size: 17px;
            line-height: 1.3;
            font-weight: 400;
            font-family: $font-family-base;
            margin-bottom: 30px;
        }

        br {
            display: none;
        }
    }

    .jumbotron-logo {
        max-width: 100%;
        margin-top: 10px;

        object {
            width: 80%;
            display: block;
            margin: auto;
            padding-top: 20px;
        }

        img {
            width: 100%;
            display: block;
            margin: auto;
            padding-top: 20px;
        }

        p {
            font-size: 14px;
            text-align: right;
        }
    }

}

@media only screen and (min-width : 370px) {

    .jumbotron {
        .container {
            padding: 0 10%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .jumbotron {
        .container {
            padding: 0;
        }

        .jumbotron-logo {
            margin-top: 10px;

            object {
                width: 60%;
            }

            img {
                width: 100%;
            }
        }

    }
}

@include media-breakpoint-up(md) {
    .jumbotron {
        min-height: 520px;

        .container {

            padding-left: 40px;
            padding-right: 40px;

            .content {
                margin-top: 55px;

                a {
                    margin-left: 0;
                }

                h2 {
                    max-width: 80%;
                }
            }

            .jumbotron-logo {
                position: absolute;
                left: 60%;
                width: 300px;
                margin: 0 auto;

                object {
                    width: 100%;
                    max-width: 100%;
                    margin: auto;
                    padding-top: 0px;
                    padding-left: 20px;
                }

                img {
                    width: 100%;
                    max-width: 100%;
                    margin: auto;
                    padding-top: 0px;
                    padding-left: 20px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .jumbotron {
        min-height: 620px;

        .container {
            .content {
                margin-top: 50px;

                h1 {
                    font-size: 65px;
                    line-height: 1.32;
                }

                p {
                    font-size: 20px;
                }
            }

            .jumbotron-logo {
                width: 350px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .jumbotron {
        min-height: 660px;

        .container {
            .content {

                h1 {
                    font-size: 66px;
                    line-height: 1.34;
                    letter-spacing: 1px;
                }

                br {
                    display: block;
                }
            }

            .jumbotron-logo {
                width: 400px;
            }
        }
    }

}